
@media (min-width:576px) {    
    .pull-sm-12 {right: 100%}
    .pull-sm-11 {right: 91.66666667%}
    .pull-sm-10 {right: 83.33333333%}
    .pull-sm-9 {right: 75%}
    .pull-sm-8 {right: 66.66666667%}
    .pull-sm-7 {right: 58.33333333%}
    .pull-sm-6 {right: 50%}
    .pull-sm-5 {right: 41.66666667%}
    .pull-sm-4 {right: 33.33333333%}
    .pull-sm-3 {right: 25%}
    .pull-sm-2 {right: 16.66666667%}
    .pull-sm-1 {right: 8.33333333%}
    .pull-sm-0 {right: auto}

    .push-sm-12 {left: 100%}
    .push-sm-11 {left: 91.66666667%}
    .push-sm-10 {left: 83.33333333%}
    .push-sm-9 {left: 75%}
    .push-sm-8 {left: 66.66666667%}
    .push-sm-7 {left: 58.33333333%}
    .push-sm-6 {left: 50%}
    .push-sm-5 {left: 41.66666667%}
    .push-sm-4 {left: 33.33333333%}
    .push-sm-3 {left: 25%}
    .push-sm-2 {left: 16.66666667%}
    .push-sm-1 {left: 8.33333333%}
    .push-sm-0 {left: auto}    
}

@media (min-width:768px) {
    .pull-md-12 {right: 100%}
    .pull-md-11 {right: 91.66666667%}
    .pull-md-10 {right: 83.33333333%}
    .pull-md-9 {right: 75%}
    .pull-md-8 {right: 66.66666667%}
    .pull-md-7 {right: 58.33333333%}
    .pull-md-6 {right: 50%}
    .pull-md-5 {right: 41.66666667%}
    .pull-md-4 {right: 33.33333333%}
    .pull-md-3 {right: 25%}
    .pull-md-2 {right: 16.66666667%}
    .pull-md-1 {right: 8.33333333%}
    .pull-md-0 {right: auto}

    .push-md-12 {left: 100%}
    .push-md-11 {left: 91.66666667%}
    .push-md-10 {left: 83.33333333%}
    .push-md-9 {left: 75%}
    .push-md-8 {left: 66.66666667%}
    .push-md-7 {left: 58.33333333%}
    .push-md-6 {left: 50%}
    .push-md-5 {left: 41.66666667%}
    .push-md-4 {left: 33.33333333%}
    .push-md-3 {left: 25%}
    .push-md-2 {left: 16.66666667%}
    .push-md-1 {left: 8.33333333%}
    .push-md-0 {left: auto}    
}

@media (min-width:992px) {    
    .pull-lg-12 {right: 100%}
    .pull-lg-11 {right: 91.66666667%}
    .pull-lg-10 {right: 83.33333333%}
    .pull-lg-9 {right: 75%}
    .pull-lg-8 {right: 66.66666667%}
    .pull-lg-7 {right: 58.33333333%}
    .pull-lg-6 {right: 50%}
    .pull-lg-5 {right: 41.66666667%}
    .pull-lg-4 {right: 33.33333333%}
    .pull-lg-3 {right: 25%}
    .pull-lg-2 {right: 16.66666667%}
    .pull-lg-1 {right: 8.33333333%}
    .pull-lg-0 {right: auto}

    .push-lg-12 {left: 100%}
    .push-lg-11 {left: 91.66666667%}
    .push-lg-10 {left: 83.33333333%}
    .push-lg-9 {left: 75%}
    .push-lg-8 {left: 66.66666667%}
    .push-lg-7 {left: 58.33333333%}
    .push-lg-6 {left: 50%}
    .push-lg-5 {left: 41.66666667%}
    .push-lg-4 {left: 33.33333333%}
    .push-lg-3 {left: 25%}
    .push-lg-2 {left: 16.66666667%}
    .push-lg-1 {left: 8.33333333%}
    .push-lg-0 {left: auto}    
}
