/*==font-family: 'Roboto', sans-serif;==*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  overflow-x: hidden;
  color: #fff;
  background-color: #1e1f22;
  position: relative;
}

* {
  margin: 0;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;
}

p {
  font-size: 14px;
  color: #fff;
  line-height: 1.6;
  margin: 0 0 15px;
  font-weight: 400;
}

a {
  color: #fff;
  text-decoration: none !important;
  transition: ease-in-out 0.3s;
  display: inline-block;
}

/*
a:hover,
a:active,
a:focus {
  color: #02eb9f;
}
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #fff;
  margin: 0;
  margin-bottom: 10px;
}

h1 {
  font-size: 36px;
  font-weight: 700;
}

h2 {
  font-size: 30px;
  line-height: 1.5;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 14px;
  color: #4a4de6;
}
h6:active {
  font-weight: 700;
  font-size: 50px;
}

ul {
  margin: 0;
}

.table thead th {
  color: #fff;
  text-align: center;
  padding: 3rem;
  font-weight: 400;
  font-size: 17px;
  border-top: 1px solid rgba(230, 231, 233, 0.2);
  border-bottom: 1px solid rgba(230, 231, 233, 0.2);
}
/* 
.table thead th:first-child {
  border-left: 1px solid rgba(230, 231, 233, 0.2);
}

.table thead th:last-child {
  border-right: 1px solid rgba(230, 231, 233, 0.2)
} */

.table tbody th img {
  display: inline-table;
  width: 2vw;
  padding-left: 0.5vw;
}

.table tbody th {
  color: rgba(255, 255, 255, 0.378);
  text-align: center;
  padding: 2.5rem;
  font-weight: 400;
  border-bottom: 1px solid rgba(230, 231, 233, 0.2);
}

input,
select,
textarea,
button,
input:focus,
button:focus {
  outline: none;
}

button {
  transition: ease-in-out 0.3s;
}

img {
  max-width: 100%;
}

header,
section,
footer {
  width: 100%;
}

.row {
  margin-right: -12px;
  margin-left: -12px;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 12px;
  padding-left: 12px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 12px;
  padding-left: 12px;
}

.sidepanel {
  width: 250px;
  position: fixed;
  z-index: 1;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 5px;
}
.sidepanelCol {
  padding: 0px 24px;
}
.panelSideLogo {
  display: none;
}
.panelMenuCol {
  display: none;
}
.panelLogoCol {
  width: 100%;
  border-bottom: 1px rgba(230, 231, 233, 0.2) solid;
  padding: 15px 0px;
  margin-bottom: 15px;
}
.panelLogoCol a {
  padding: 0px !important;
}
.sidepanel a {
  padding: 12px 0px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #b7b7b7;
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.sidepanel a i {
  opacity: 0.7;
}
.sidepanel a:hover i,
.sidepanel a.active i {
  opacity: 1;
}
.sidepanel a:hover,
.sidepanel a.active {
  font-weight: 500;
  color: #fff;
}
.sidepanel a i {
  width: 25px;
  text-align: center;
  display: inline-block;
  margin-right: 14px;
}

.sidepanel a:hover {
  color: #f1f1f1;
}

.notificationPil {
  color: #fff;
  background: #ffa000;
  font-size: 12px;
  font-weight: 500;
  opacity: 1 !important;
  padding: 3px;
  border-radius: 100px;
  min-width: 24px;
  display: inline-block;
  text-align: center;
  margin-left: auto;
}

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  display: none;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

.main {
  display: flex;
}

.leftSection {
}
.rightSection {
  width: calc(100% - 250px);
  margin-left: 250px;
  padding: 48px;
}

.darkPanel {
  background: #0c0c0e;
  border-radius: 8px;
  padding: 18px 20px;
  margin-bottom: 24px;
}

.tradePanel {
  background: #0c0c0e;
  border-radius: 8px;
}

.boxHead {
  justify-content: space-between;
}
.boxHead_L {
  padding-right: 10px;
}
.boxHead_L h6 {
}
.boxHead_L h6 > span {
  padding-right: 6px;
}
.boxHead_L h2 > span {
  font-size: normal;
  font-family: Montserrat;
  padding: 5vw;
  padding-bottom: 3vh;
}
.boxHead_L h6 > span:last-child {
  padding-right: 0px;
}
.boxHead_R {
}
.boxHead_R i {
  display: inline-block;
  margin-bottom: 5px;
}

.sub_T {
  font-size: 12px;
  opacity: 0.5;
  font-weight: 500;
  margin-bottom: 10px;
}
.price_ {
  font-size: 16px;
  font-weight: 500;
}
.navyBlue {
  color: #0b071d;
}

.progressBarStatus {
  padding-top: 10px;
}
.progress_head {
  display: flex;
  justify-content: space-between;
}
.progress_head .pro_title {
  font-size: 12px;
  font-weight: 500;
  padding-right: 10px;
}
.progress_head .pro_percent {
  font-size: 16px;
  font-weight: 500;
}
.progress {
  height: 6px;
  border-radius: 4px;
  background: #f0f1f2;
}
.blueBar {
  background: linear-gradient(270deg, #60b0fa 0%, #4a4de6 100%);
}

.greenText {
  color: #4caf50 !important;
}
.redText {
  color: #ef4234 !important;
}
.redDarkText {
  color: #dd425a !important;
}

.sub_T_dark {
  color: rgba(24, 24, 25, 0.42);
}

.aprPanel {
  background-image: url(../img/img_01.png);
  background-repeat: no-repeat;
  background-position: top right;
}

.wrap_400 {
  width: 100%;
  max-width: 400px;
}

.btn_ {
  padding: 11px 12px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  height: 40px;
  border-radius: 8px;
  background: #4a4de6;
  border: none;
  margin-bottom: 15px;
}
.btn_full {
  width: 100%;
}

.btn_yellow {
  background: #dcb400 !important;
}

.inputBtnCol {
  position: relative;
  width: 100%;
  display: flex;
  background: #e6e6e6;
  border-radius: 8px;
  margin-bottom: 15px;
}
.inputBtnCol input {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  border: none;
  padding: 5px 10px;
  background-color: transparent;
}
.inputBtnCol button {
  padding: 11px 12px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  height: 40px;
  border-radius: 8px;
  background: #4a4de6;
  border: none;
}

.inputBtnCol input::-webkit-outer-spin-button,
.inputBtnCol input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputBtnCol input[type="number"] {
  -moz-appearance: textfield;
}
.feeText {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 15px;
}
.feeText .percentColor {
  color: #4a4de6;
}

.claimCoin {
  font-size: 21px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}
.claimCoin span {
  color: #4a4de6;
}

.tipsRow {
  border-top: 1px #e6e7e9 solid;
  margin-left: -20px;
  margin-right: -20px;
}
.tipsCol {
  padding-top: 35px;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
}
.tipsCol h5 {
  margin-bottom: 30px;
}
.tipsList {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.tipsList li {
  margin-bottom: 18px;
}

.walletList {
  margin-bottom: 15px;
}
.walletList > li {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.blueText {
  color: #4a4de6 !important;
}
.textBold {
  font-weight: 700;
}
.walletList > li .label_ {
  margin: 0px;
}
.walletList > li .amt_ {
}
.walletList > li .amt_ span {
  margin-left: 4px;
}

.walletList_1st {
}
.walletList_1st > li {
  padding: 4px 0px;
}

.viewHistory {
  text-align: center;
  font-size: 13px;
  padding: 15px 0px;
}
.viewHistory img {
  margin-left: 5px;
}

.text_light {
  color: rgba(255, 255, 255, 0.5) !important;
}

.walletFooter {
  border-top: 1px #e6e7e9 solid;
  margin-left: -20px;
  margin-right: -20px;
}
.walletFooter .detailBtn_ {
  padding: 15px 5px 0px;
  color: #4a4de6;
  font-weight: 500;
}
.walletFooter .detailBtn_ svg {
  margin-left: 5px;
}

.walletFooter .detailBtn_:hover {
  color: #dcb400;
}

.actionCard {
  padding-top: 1vh;
  position: inherit;
  display: grid;
  text-align: center;
  width: 7vw;
  border-radius: 15px;
  background: #4a4de6;
}

.actionCard2 {
  padding-top: 1vh;
  position: inherit;
  display: grid;
  text-align: center;
  width: 6vw;
  height: 70px;
  border-radius: 15px;
  background: #4a4de6;
}

.actionCard span {
  padding-left: 20px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
}

.actionCard2 span {
  padding-left: 20px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
}

.dropdown-toggle {
  font-size: 18px;
  font-weight: 600;
  border-radius: 15px;
  text-decoration: initial;
  padding-left: 20px;
  padding-bottom: 2vh;
  text-align: left;
  width: 12vw;
  height: 40px;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.paginator {
  display: flex;
  margin: 3vh;
}

.paginator .prev {
  padding-right: 2vw;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}

.paginator .page {
  padding: 0 1vw;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}

.paginator .next {
  padding-left: 2vw;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}

.errorPanel {
  justify-content: center;
  align-content: center;
  position: relative;
  top: 25vh;
  left: 25vw;
}

@media (max-width: 600px) {
  .col-xl-auto {
    padding-right: 2px;
    padding-left: 2px;
  }

  .rightSection {
    display: flex;
    width: fit-content;
    margin-top: 100px;
    margin-left: 0;
    padding: 2px;
  }

  .panelLogoCol {
    position: absolute;
    width: 73px;
    height: 50px;
    left: 16px;
    top: 16px;
    padding: 2px 0px;
    margin-bottom: 2px;
    border: none;
  }

  .container-xl {
    padding-right: 2px;
    padding-left: 2px;
  }
  .sidepanel {
    width: auto;
    position: fixed;
    z-index: 1;
    padding-top: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    min-height: 0px;
    height: 82px;
  }

  .sidepanelCol {
    display: block;
    position: fixed;
    top: 0;
    border-radius: 50px 0px 0px 0px;
    right: 0;
    width: 60vw;
    height: 2858px;
    background: #0b0b0c;
    transition: 0.5s;
    transform-origin: right;
  }

  .sidepanelMobCol {
    display: none;
  }

  /* .panelMenuCol2 {
    display: ;
  } */

  .panelLogoCol {
    position: fixed;
    z-index: 99;
    padding-top: 0;
    margin-top: 0;
    top: 0vh;
  }

  .panelMenuCol {
    position: fixed;
    display: block;
    top: 2vh;
    right: 10vw;
  }

  .panelSideLogo {
    display: flex;
    border-bottom: 0.1px solid #e6e7e9;
    transition: 0.5s;
    padding: 10px;
  }

  .panelLinks {
    position: relative;
    top: 3vh;
  }

  .col-4 {
    max-width: 100%;
  }

  .col-8 {
    max-width: 100%;
  }

  .col-12 {
    max-width: 100%;
  }

  .btn_obj {
    justify-content: center;
    padding: 12px;
    min-width: 300px;
    border-bottom: 5px;
    margin: 0px auto;
  }

  .btn_ {
    border-radius: 30px;
  }

  .inputBtnCol {
    border-radius: 30px;
  }

  .claimCoin {
    justify-content: space-evenly;
  }

  .darkPanel {
    position: relative;
    background: #0c0c0e;
    border-radius: 20px;
    border: 1px solid #3a3941;
    padding: 2px;
    min-width: 344px;
    height: auto;
  }

  .boxHead {
    padding: 15px;
  }
  .progressBarStatus {
    padding: 15px;
    padding-top: 0;
  }

  .aprPanel {
    justify-content: center;
    height: 1721px;
    background-image: url(../img/img_01.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-position-y: 90vh;
  }

  .aprPanel h6 {
    padding: 15px;
    padding-bottom: 5px;
  }

  .aprPanel p {
    padding-left: 15px;
  }

  .tipsRow {
    justify-content: center;
    margin: 0px 15px;
    border-top: none;
    padding-top: 45vh;
  }

  .tipsRow h5 {
    font-size: 20px;
  }

  .tipsList li {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }

  .row {
    justify-content: center !important;
  }

  .walletList {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .walletList > li .label_ {
    margin: 0 2vw !important;
  }

  .viewHistory {
    text-align: right;
  }

  .walletFooter {
    margin: auto;
    margin-top: 0;
  }
  .walletFooter .detailBtn_ {
    padding-top: 30px;
    padding-bottom: 60px;
    color: #4a4de6;
    font-weight: 500;
    font-size: 14px;
  }

  .table thead th {
    padding: 1.5rem;
    font-weight: 400;
    font-size: 12px;
    border-top: 1px solid rgba(230, 231, 233, 0.2);
    border-bottom: 1px solid rgba(230, 231, 233, 0.2);
  }

  .table tbody th {
    padding: 1.5rem;
    font-weight: 400;
    font-size: 10px;
  }

  .actionCard {
    width: 25vw;
    height: 7vh;
    font-size: 15px;
  }

  .actionCard span {
    padding-top: 1vh;
    font-size: 10px;
  }

  .dropdown-toggle {
    font-size: 12px;
    padding-top: 0;
    padding-left: 1cw;
  }

  .actionCard2 {
    width: 20vw;
    height: 7vh;
    font-size: 15px;
  }

  .actionCard2 span {
    padding-top: 1vh;
    font-size: 10px;
  }

  .paginator {
    margin-right: 40vh;
  }

  .small-text {
    font-size: 1rem;
  }
}
