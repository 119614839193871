@media (min-width: 1200px) {
	.container {max-width: 1170px;}
}
@media (min-width: 1400px) {
	.container {max-width: 1370px;}
}
@media (min-width: 1500px) {}
@media (max-width: 1366px) {}
@media (max-width: 1280px) {}
@media (max-width: 1199px) {}
@media (max-width: 1024px) {}
@media (max-width: 991px) {}
@media (max-width: 860px) {}
@media (max-width: 768px) {}
@media (max-width: 767px) {}
@media (max-width: 575px) {}
@media (max-width: 480px) {}
@media (max-width: 414px) {}
@media (max-width: 375px) {}